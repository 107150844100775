import './PageLoader.css';

const PageLoader = () => {
  return (
    <div className="parent">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <h5 style={{ paddingTop: '100px' }}>Loading...</h5>
      </div>
    </div>
  );
};

export default PageLoader;
