import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const contactUsFormApi = createApi({
  reducerPath: 'contactUsFormApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/contact-us`,
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['ContactUsForm'],
  endpoints: builder => ({
    createContactUsMessage: builder.mutation({
      query: message => ({
        url: '',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['ContactUsForm'],
    }),
  }),
});

export const { useCreateContactUsMessageMutation } = contactUsFormApi;
