import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const checkCompanyApi = createApi({
  reducerPath: 'checkCompanyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/check-company`,
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['CheckCompany'],
  endpoints: builder => ({
    checkCompany: builder.query({
      query: prefix => `/${prefix}`,
      providesTags: ['CheckCompany'],
    }),
  }),
});

export const { useCheckCompanyQuery } = checkCompanyApi;
