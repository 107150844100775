import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const loginByQRApi = createApi({
  reducerPath: 'loginByQRApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/login`,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['LoginByQR'],
  endpoints: builder => ({
    loginByQR: builder.mutation({
      query: data => ({
        url: '',
        method: 'post',
        body: data,
      }),
      invalidatesTags: ['LoginByQR'],
    }),
  }),
});

export const { useLoginByQRMutation } = loginByQRApi;
