import httpCommon from '../axios';
import useAuth from './useAuth';
import useCompany from './useCompany';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const { setCompany } = useCompany();

  const company = localStorage.getItem('company');
  const companyData = JSON.parse(company);

  const refresh = async () => {
    try {
      // update your access token
      const response = await httpCommon.post('/refresh');

      const access_token = response?.data?.data?.access_token;
      const user = response?.data?.data?.user;
      const roles = [2000];
      setAuth({ roles, access_token, user });
      setCompany(companyData);

      return access_token;
    } catch (err) {
      console.log('Refresh endpoint error: ', err);
    }
  };
  return refresh;
};

export default useRefreshToken;
