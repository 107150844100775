import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PersistLogin from './Auth/Components/PersistLogin';
import RequireAuth from './Middleware/RequireAuth';
import PageLoader from './Common/PageLoader/PageLoader';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// merchant pages

const MerchantLayout = lazy(() => import('./Consumer/Layout/MerchantLayout'));
const MerchantHome = lazy(() => import('./Consumer/Pages/Home/MerchantHome'));
const PaymentTab = lazy(() => import('./Consumer/Pages/Payment/FullBalance/tabs/PaymentTab'));
const ChoosePayment = lazy(
  () => import('./Consumer/Pages/Payment/FullBalance/ChoosePayment/ChoosePayment')
);
const PaymentConfirmation = lazy(
  () => import('./Consumer/Pages/Payment/FullBalance/PaymentConfirmation/PaymentConfirmation')
);
const ReducedChoosePayment = lazy(
  () => import('./Consumer/Pages/Payment/ReducedBalance/ChoosePayment/ReducedChoosePayment')
);
const ReducedPaymentConfirmation = lazy(
  () =>
    import('./Consumer/Pages/Payment/ReducedBalance/PaymentConfirmation/ReducedPaymentConfirmation')
);
const ReducedPaymentTab = lazy(
  () => import('./Consumer/Pages/Payment/ReducedBalance/tabs/ReducedPaymentTab')
);
const CreatePaymentSchedule = lazy(
  () => import('./Consumer/Pages/Payment/PaymentSchedule/CreatePaymentSchedule')
);
const EditPaymentSchedule = lazy(
  () => import('./Consumer/Pages/Payment/PaymentSchedule/EditPaymentSchedule')
);
const ListSchedule = lazy(() => import('./Consumer/Pages/Payment/PaymentSchedule/ListSchedule'));
const AccountActivity = lazy(() => import('./Consumer/Pages/Activity/AccountActivity'));
const TransactionHistory = lazy(
  () => import('./Consumer/Pages/MyAccount/TransactionHistory/TransactionHistory')
);
const ConsumerOffers = lazy(() => import('./Consumer/Pages/MyAccount/Offers/ConsumerOffers'));

const AccountTab = lazy(() => import('./Consumer/Pages/MyAccount/MyAccountTab/AccountTab'));
const Preference = lazy(() => import('./Consumer/Pages/MyAccount/Preference/Preference'));
const Wallet = lazy(() => import('./Consumer/Pages/MyAccount/Wallet/Wallet'));
const Device = lazy(() => import('./Consumer/Pages/MyAccount/Device/Device'));
const Agreements = lazy(() => import('./Consumer/Pages/MyAccount/AgreementLetter/AgreementLetter'));

// public pages
const WorldLayout = lazy(() => import('./World/Layout/WorldLayout'));
const WorldHome = lazy(() => import('./World/Pages/Home/WorldHome'));
const ContactUs = lazy(() => import('./World/Pages/ContactUs/ContactUs'));
const FAQ = lazy(() => import('./World/Pages/Faqs/FAQ'));
const LoginAsConsumer = lazy(() => import('./World/Pages/LoginAsConsumer/LoginAsConsumer'));
const LoginByQR = lazy(() => import('./World/Pages/LoginByQR/LoginByQR'));
const PageNotFound = lazy(() => import('./Common/PageNotFound'));
const Unauthorized = lazy(() => import('./Common/Unauthorized'));
const NoAccess = lazy(() => import('./Common/NoAccess/NoAccess'));

const ROLES = {
  MERCHANT: 2000,
};

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route element={<PersistLogin />}>
            {/*  merchant/consumers routes */}
            <Route element={<RequireAuth allowedRoles={[ROLES.MERCHANT]} />}>
              <Route path="/merchant" element={<MerchantLayout />}>
                <Route path="" element={<MerchantHome />} />
                <Route path="faqs" element={<FAQ />} />
                <Route path="contact-us" element={<ContactUs />} />
                <Route path="my-account" element={<AccountTab />}>
                  <Route index element={<Wallet />} />
                  <Route path="wallets" element={<Wallet />} />
                  <Route path="preference" element={<Preference />} />
                  <Route path="device" element={<Device />} />
                  <Route path="agreements" element={<Agreements />} />
                  <Route path="transaction-history" element={<TransactionHistory />} />
                  <Route path="consumer-offers" element={<ConsumerOffers />} />
                </Route>

                <Route path="payment" element={<PaymentTab />}>
                  <Route path="choose-payment" element={<ChoosePayment />} />
                  <Route path="payment-confirmation" element={<PaymentConfirmation />} />
                </Route>

                <Route path="accept-offer" element={<ReducedPaymentTab />}>
                  <Route path="choose-payment" element={<ReducedChoosePayment />} />
                  <Route path="payment-confirmation" element={<ReducedPaymentConfirmation />} />
                </Route>
                <Route path="schedules">
                  <Route index element={<ListSchedule />} />
                  <Route path="create-schedule" element={<CreatePaymentSchedule />} />
                  <Route path="edit-schedule/:scheduleId" element={<EditPaymentSchedule />} />
                </Route>
                <Route path="account-activity" element={<AccountActivity />} />
              </Route>
            </Route>
          </Route>
          {/* common public routes */}
          <Route path="/" element={<WorldLayout />}>
            <Route path="/" element={<WorldHome />} />
            <Route path="/faqs" element={<FAQ />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="/signin" element={<LoginAsConsumer />} />
            <Route path="/login-by-code" element={<LoginByQR />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/no-access" element={<NoAccess />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
