import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AuthProvider } from './Context/AuthProvider';
import { PaymentProvider } from './Context/PaymentProvider';
import { CompanyProvider } from './Context/CompanyProvider';

import { store } from './Store/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CompanyProvider>
        <AuthProvider>
          <PaymentProvider>
            <QueryClientProvider client={queryClient}>
              <Provider store={store}>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </Provider>
            </QueryClientProvider>
          </PaymentProvider>
        </AuthProvider>
      </CompanyProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
