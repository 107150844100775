import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const termsApi = createApi({
  reducerPath: 'termsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/terms`,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['CompanyTerms'],
  endpoints: builder => ({
    getCompanyTerms: builder.query({
      query: company_id => `/${company_id}`,
      providesTags: ['CompanyTerms'],
    }),
  }),
});

export const { useGetCompanyTermsQuery } = termsApi;
