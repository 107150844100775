import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from '../../Hooks/useRefreshToken';
import useAuth from '../../Hooks/useAuth';
import PageLoader from '../../Common/PageLoader/PageLoader';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    !auth?.access_token ? verifyRefreshToken() : setIsLoading(false);
  }, [auth?.access_token, refresh]);

  return <div>{isLoading ? <PageLoader /> : <Outlet />}</div>;
};

export default PersistLogin;
