import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const privacyPolicyApi = createApi({
  reducerPath: 'privacyPolicyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/privacy-policy`,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['CompanyPrivacyPolicy'],
  endpoints: builder => ({
    getCompanyPrivacyPolicy: builder.query({
      query: company_id => `/${company_id}`,
      providesTags: ['CompanyPrivacyPolicy'],
    }),
  }),
});

export const { useGetCompanyPrivacyPolicyQuery } = privacyPolicyApi;
