import { createContext, useState } from 'react';

const CompanyContext = createContext({});

export const CompanyProvider = ({ children }) => {
  const [company, setCompany] = useState({});

  return (
    <CompanyContext.Provider value={{ company, setCompany }}>{children}</CompanyContext.Provider>
  );
};

export default CompanyContext;
